/* eslint-disable react/display-name */
import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import { Converter } from "showdown"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import { getPostObj } from "../utils/utils"

import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import ImageMeta from "../components/ImageMeta"
import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"

import SideCTA from "../components/CTA/SideCTA"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

const WhiteningForLife = ({ data, pageContext, location }) => {
  let language = "en"

  let post = data.allInstructionsJson.nodes[0]

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          lang={language}
          title="Free Teeth Whitening for Life Program"
          description="When you enroll in Full Smile Dental's Free Teeth Whitening for Life Program, you will receive one bleach syringe every 6 months to keep your smile bright and radiant."
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />
        <div className="columns top-section color-back">
          <div className="column is-4"></div>
          <div className="column">
            <h1>Free Whitening for Life Program</h1>
          </div>
          <div className="column is-4"></div>
        </div>
        <div className="body-section joshua-tree-content instruction-content">
          <div className="columns is-desktop">
            <div className="column is-3"></div>
            <div className="column">
              <MarkdownViewer markdown={post.body} />
            </div>
            <div className="column is-2"></div>
            <div className="column is-narrow">
              <SideCTA
                className="single-instruction-questions"
                sideColumnIs={0}
                subheading={language === "es" ? "Preguntas?" : "Questions?"}
                heading={
                  language === "es"
                    ? "Estamos Aquí Para Ayudar"
                    : "We Are Here To Help"
                }
                paragraph={
                  language === "es"
                    ? "Póngase en contacto con nuestra oficina si tiene alguna pregunta o problema con estas instrucciones."
                    : "Please contact our office if you have any questions."
                }
                buttonText={language === "es" ? "Contáctenos" : "Contact Us"}
                buttonUrl={
                  language === "es"
                    ? "/es/comuniquese-con-nosotros/"
                    : "/contact/"
                }
              />
              <div style={{ height: 30 }}></div>

              <SideCTA
                className=""
                sideColumnIs={0}
                subheading={language === "es" ? "Versión PDF" : "PDF Version"}
                heading={
                  language === "es"
                    ? "Instrucciones de Descarga"
                    : "Download Terms and Conditions"
                }
                paragraph={
                  language === "es"
                    ? "Sigue las instrucciones sobre la marcha. Descargar en formato PDF."
                    : ""
                }
                buttonText={language === "es" ? "Descargar" : "Download"}
                buttonUrl="https://res.cloudinary.com/nuvolum/image/upload/v1612382033/FLSD/DEV/210202_FLDS_Free_Whitening_for_life_FINAL.pdf"
                download
              />
            </div>
            <div className="column is-3"></div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query Whitening {
    allInstructionsJson(filter: { title: { eq: "whitening-for-life/" } }) {
      nodes {
        body
      }
    }
  }
`

export default WhiteningForLife
